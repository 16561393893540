<template>
  <div class="menu">
    <el-menu
      class="menu-nav"
      router
      :default-active="$route.path"
      :unique-opened="true"
      :collapse="isCollapse"
    >
      <template v-for="item in menuList">
        <el-submenu
          v-if="item.children && item.children.length"
          :key="item.index"
          :index="item.index"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item
            v-for="child in item.children"
            :key="child.index"
            :index="child.path"
          >{{ child.name }}</el-menu-item>
        </el-submenu>
        <template v-else>
          <el-menu-item :index="item.path" :key="item.index">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    menuList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    // select(path, item) {
    //   let { menuList } = this;
    //   let title = '';
    //   let selected = [];
    //   menuList.forEach(menu => {
    //     if (menu.path === path) {
    //       title = menu.name;
    //       selected.push({ name: menu.name });
    //     } else if (menu.index == item[0]) {
    //       selected.push({ name: menu.name });
    //       let child = {};
    //       menu.children.forEach(el => {
    //         if (el.path == path) child = el;
    //       });
    //       title = child.name;
    //       selected.push({ name: child.name });
    //     }
    //   });
    //   this.$emit('select', { selected, title });
    // }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  .el-menu {
    border: none;
    .el-menu--inline .el-menu-item {
      padding-left: 50px !important;
    }
  }
  .menu-nav {
    height: 100%;
    min-height: calc(100vh - 190px);
    padding: 22px 0;
    box-sizing: border-box;
    text-align: left;
    border-radius: 3px;
  }
}
</style>
