import API from "@/api";

export default {
  getSystemAttribute: params => {
    return API.requestGet({
      url: "index/getSystemAttribute",
      auth: true
    });
  },
  // navs: params => {
  //   return API.requestPost({
  //     url: "role/getRoleByUserId",
  //     data: params,
  //     auth: true
  //   });
  // }
  // 发送验证码
  getCode: params => {
    return API.requestPost(
      {
        url: "/api/sms/sendSms",
        data: params
      },
      false
    );
  },
  saveAccountInfo: params => {
    return API.requestPost({
      data: params,
      url: "admin/edi"
    });
  },
};
