<template>
  <div class="head fx-v-center">
    <div class="head-left ft-sz-20 ft-bold">后台管理</div>
    <div class="head-center">
      <button title="折叠" @click="$emit('collapse-menu')">
        <i class="el-icon-s-fold"></i>
      </button>
    </div>
    <div class="head-right fx-v-center">
      <!-- <el-badge :value="2" class="head-right-news">
        <span class="el-icon-bell"></span>
      </el-badge>-->
      <div class="head-right-attributes" v-if="pro">
        <el-tag
          size="small"
          v-for="(attr, index) in attributes"
          :key="index"
        >{{ attr.name }}: {{ attr.value }}</el-tag>
      </div>
      <el-dropdown trigger="click" class="head-right-drop" @command="handleCommand">
        <div class="avatar">
          <!-- <el-avatar
            :size="30"
            shape="circle"
            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
          ></el-avatar>-->
<!--          <span class="ft-sz-15 ft-medium">{{ userName }}</span>-->
          <span class="ft-sz-15 ft-medium">个人中心</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="reset">账户信息</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="账户信息" width="500px" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="close">
      <el-form class="form" ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="账户" prop="account">
          <el-input v-model="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="code" prop="code" label="验证码">
          <el-input v-model.trim="form.code" placeholder="短信验证码"></el-input>
          <el-button
            type="primary"
            class="code-btn"
            :disabled="!!seconds || !form.mobile"
            @click="getCode('form')"
          >{{codeBtn}}</el-button>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="form.password" autocomplete="off" placeholder="留空则不修改密码" type="password" show-password></el-input>
        </el-form-item>
        <el-form-item label="请重复密码" prop="re_password">
          <el-input v-model="form.re_password" autocomplete="off" placeholder="请重复密码" type="password" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitReset">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from '../api';
import { MessageBox, Message} from "element-ui";

export default {
  computed: {
    codeBtn() {
      return this.seconds ? this.seconds + ' s' : '获取验证码';
    },
    account() {
      let user = this.$store.state.XINYOU_ADMIN_USER || {};
      return {
        id: user.id || '',
        name: user.account || '',
        mobile: user.mobile || '********'
      };
    },
  },
  data() {
    var checkPsd = (rule, value, callback) => {
      if (this.form.re_password !== this.form.re_password) {
        this.$refs.form.validateField("re_password");
      }
      callback();
    };
    var checkRepsd = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userName: this.$store.state.XINYOU_ADMIN_USER.nickname || this.$store.state.XINYOU_ADMIN_USER.account,
      attributes: [],
      dialogFormVisible: false,
      seconds: 0,
      form: {
        account: "",
        mobile: "",
        password: "",
        re_password: "",
        code: "",
      },
      rules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            pattern: /^[a-zA-z]\w{3,9}$/,
            message:
              "账号应为4-10位字符，可使用数字、字母、下划线，且以字母开头",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: "请输入正确格式的手机号",
            trigger: "blur",
          },
        ],
        password: [
          { validator: checkPsd, trigger: "blur" },
          {
            pattern: /^[a-zA-z0-9]\w{5,19}$/,
            message: "6-20位字符，由数字、字母、下划线组成，且不能以下划线开头",
            trigger: "blur",
          },
        ],
        re_password: [{ validator: checkRepsd, trigger: "blur" }],
      },
    };
  },
  methods: {
    // toIndex() {
    //   this.$router.push('/control');
    // },
    handleCommand(cmd) {
      switch (cmd) {
        case 'logout':
          localStorage.removeItem('xinyou_admin_user');
          localStorage.removeItem('token');
          this.$router.push({ path: '/login' });
          break;
        case "reset":
          this.openReset();
          break;
      }
    },
    openReset() {
      this.form.account = this.account.name;
      this.form.mobile =  this.account.mobile;
      this.dialogFormVisible = true;
    },
    //   获取验证码
    getCode() {
      // 校验手机号
      let check = false;
      this.$refs['form'].validateField('mobile', err => (check = !err));
      if (!check || this.seconds > 0) return;
      clearTimeout(this.timer);
      return service.getCode({ phone: this.form.mobile }).then(res => {
        let now = new Date().getTime() + 60 * 1000;
        this.till = now;
        this.countDown();
      });
    },
    // 倒计时
    countDown() {
      // 当前秒数取整
      let seconds = ~~((this.till - new Date().getTime()) / 1000);
      if (seconds > 0) {
        this.seconds = seconds;
        this.timer = setTimeout(() => this.countDown(), 1000);
      } else {
        this.seconds = 0;
      }
    },
    async submitReset() {
      // 校验
      const validatePass = await this.$refs["form"].validate();
      if (!validatePass) return;

      // 提示确认信息
      const confirmYes = await MessageBox.alert(
        `本操作不可还原，确定您输入的信息输入无误吗？`,
        "警告",
        { showCancelButton: true }
      )
        .then(() => true)
        .catch(() => false);
      if (!confirmYes) return;

      // 保存信息
      service.saveAccountInfo({
        id: this.account.id,
        mobile: this.form.mobile,
        account: this.form.account,
        password: this.form.password,
        code: this.form.code,
      }).then((_) => {
        sessionStorage.clear();
        this.dialogFormVisible = false;

        Message.success("修改成功, 即将重新登录");
        setTimeout(() => {
          this.$router.replace({ path: "/login" });
        }, 2000);
      });
    },
    getSystemAttribute() {
      return service.getSystemAttribute().then(res => (this.attributes = res || []));
    },
    close(){
      this.dialogFormVisible = false;
      this.$refs.form.resetFields();
    }
  },
  mounted() {
    this.getSystemAttribute();
  }
};
</script>

<style lang="scss" scoped>
.head {
  padding: 0 80px;
  height: 70px;
  background-color: #fff;
  &-left {
    width: 270px;
    padding-left: 20px;
    margin-right: 20px;
    text-align: left;
    cursor: pointer;
    box-sizing: border-box;
  }
  &-center {
    button {
      border: none;
      background: transparent;
      font-size: 22px;
      cursor: pointer;
      outline: none;
      line-height: 70px;
    }
  }
  &-right {
    margin-left: auto;
    &-news {
      margin-right: 20px;
      font-size: 20px;
    }
    &-drop {
      .avatar {
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .el-tag {
      margin-right: 10px;
    }
  }
  .code {
    .el-input {
      width: 68%;
      margin-right: 3%;
    }
    &-btn {
      width: 28%;
      min-width: 85px;
      padding: 12px 10px;
    }
  }
}
</style>
