<template>
  <div class="home">
    <!-- header -->
    <home-header @collapse-menu="collapse = !collapse"></home-header>
    <div class="home-body">
      <user-bar></user-bar>
      <el-container class="home-body-content">
        <!-- 左侧导航栏 -->
        <el-aside :width="asideWidth">
          <side-bar :is-collapse="collapse" :menu-list="menuList"></side-bar>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import homeHeader from './components/home-header';
import userBar from './components/user-bar';
import sideBar from './components/side-bar';

import service from './api';

export default {
  data() {
    return {
      collapse: false,
      menuList: []
    };
  },
  components: {
    homeHeader,
    userBar,
    sideBar
  },
  computed: {
    // 导航栏宽度
    asideWidth() {
      return this.collapse ? 'auto' : '270px';
    }
  },
  created() {
    // 获取并将用户信息存储到store
    let user = JSON.parse(localStorage.getItem('xinyou_admin_user')) || {};
    this.$store.commit('setUser', user);
    this.menuList = user.navs || [];
  }
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  &-body {
    min-height: calc(100vh - 70px);
    background-color: #f2f5f7;
    &-content {
      margin-top: -40px;
      padding: 0 80px;
      .el-main {
        position: relative;
        padding: 0;
        padding-bottom: 70px;
        margin-left: 20px;
      }
    }
  }
}
</style>